<template>
  <a-row class="combo-lists">
    <div class="body">
      <a-row class="filter">
        <div class="title">Course List</div>
        <a-input-search v-model:value="searchString" placeholder="Search here ..." @search="onSearch"/>
         <a-button type="primary">
            <router-link :to="'/client/group_courses' + loginAs"><GroupOutlined />  Group
            </router-link>
          </a-button>
      </a-row>
      <div class="users">
        <a-table :columns="columns" :data-source="courses" class="ant-table-striped"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :pagination="{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
          }"
          rowKey="_id"
        >
          <template #price="{ text: price }">
            <span>
              <a-tag color="green">£ {{ price }} </a-tag>
            </span>
          </template>
        </a-table>
      </div>
    </div>
  </a-row>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue'
import { notification } from 'ant-design-vue'
import { GroupOutlined } from '@ant-design/icons-vue'

import ICourse from '../../../interface/course'
import commonServices from '../../../services/common'
import courseService from '../../../services/course'
import searchService from '../../../services/filter'

export default defineComponent({
  components: {
    GroupOutlined
  },
  props: ['clientId', 'watchKey'],
  setup (props) {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const columns = [
      {
        title: 'Courses',
        dataIndex: 'title'
      },
      {
        title: 'Price',
        dataIndex: 'price'
      },
      {
        title: 'Course Type',
        dataIndex: 'type'
      },
      {
        title: 'Total Credits',
        dataIndex: 'cpd_points'
      },
      {
        title: 'Current Balance',
        dataIndex: 'cpd_bal_points'
      }
    ]
    const courses = ref<Array<ICourse>>([])
    const courseData = ref<Array<ICourse>>([])
    const searchString = ref<string>('')
    const onSearch = async (searchValue: string) => {
      courses.value = searchService.search(courseData.value, searchValue)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getCourse = async () => {
      const cId = commonServices.getClientId()
      const clientId = (props.clientId ? props.clientId : cId)
      const singleCourse = await courseService.getClientCoursesByType(clientId, 'Single')
      const groupCourse = await courseService.getClientCoursesByType(clientId, 'Group')
      courses.value = [...singleCourse.data, ...groupCourse.data]
      courseData.value = [...singleCourse.data, ...groupCourse.data]
    }
    watch(() => props.watchKey, () => {
      getCourse()
    })
    onMounted(() => {
      getCourse()
    })
    return {
      columns,
      onSearch,
      searchString,
      notify,
      courses,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.combo-lists {
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 15px;
    width: 100%;
    .title {
      color: #707070;
      float: left;
      font-size: 20px;
      text-transform: capitalize;
      font-family: "TT Norms Pro Medium";
    }
    .filter {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 0 0;
      .noofentries {
        display: flex;
        align-items: center;
        .ant-select {
          margin: 0 5px;
        }
      }
      .ant-input-affix-wrapper {
        width: 200px;
      }
      .ant-select,
      .ant-btn {
        float: left;
      }
      .ant-btn {
        background: #38B6FF;
        border-color: #38B6FF;
        border-radius: 20px;
        font-family: "TT Norms Pro Medium";
      }
    }
    .users {
      margin-top: 20px;
      font-family: "TT Norms Pro Medium";
      .ant-table {
        color: #646464;
        .ant-table-thead {
          tr {
            th {
              background: #E6E6E6;
              color: #646464;
              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }
              &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }
            }
          }
        }
        .ant-table-tbody {
          tr {
            td {
              border-bottom:none;
              &:nth-child(2){
                color: #38B6FF;
              }
            }
          }
        }
      }
      .ant-pagination {
        .ant-pagination-options {
          // display: none;
        }
        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 50%;
          background-color: #ededed;
          border: none;
        }
        .ant-pagination-item {
          background-color: #ededed;
          margin-right: 0px;
          border: none;
          font-family: "TT Norms Pro Regular";
        }
        li:nth-child(2) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
        li:nth-last-child(3n) {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .ant-pagination-next {
          margin-left: 8px;
        }
        .ant-pagination-item-active {
          background-color: #ededed;
          a {
            background: #6f64f8;
            color: #fff;
            border-radius: 50%;
          }
        }
      }
      .totalentries {
        font-family: "TT Norms Pro Regular";
        text-align: left;
        margin: -40px 0 0 0;
        color: #646464;
        p {
          margin: 0;
        }
      }
    }
  }
}

</style>
<style scoped>
.ant-table-striped :deep(.table-striped) {
  background-color: #F7FAFC;
}
</style>
