<template>
  <div class="main">
    <div class="client-view content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <router-link to="/dashboard"><span>Dashboard</span></router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item> <router-link to="/clients"><span>Client</span></router-link> </a-breadcrumb-item>
        <a-breadcrumb-item> View </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="add">
            <a-button type="primary">
              <router-link to="/clients"><ArrowLeftOutlined /> Back To Client</router-link>
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="view-candidate">
        <a-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
          <div class="candidateDetails">
            <div class="mapsection" v-if="center">
              <GoogleMap api-key="AIzaSyAXqAPJ-888SUMOzduhvF4eI9CwGsutjUc" style="width: 100%; height: 200px" :center="center" :zoom="15">
                <Marker :options="{ position: center }" />
              </GoogleMap>
            </div>
            <div class="contents">
              <div class="fields">
                <label>Name</label>
                <span>{{client?.client_name}}</span>
                <label>Email</label>
                <span>{{client?.email}}</span>
                <label>Address</label>
                <span>{{client?.address?.address1}}, {{client?.address?.country}}</span>
                <label>Phone</label>
                <span>{{client?.phone}}</span>
              </div>
              <div class="contactperson">
                <p><span> Contact Name:</span> {{client?.first_name}} {{client?.last_name}}</p>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="17" :xl="17">
          <div class="tabsection">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="1" tab="Manage Payment">
                <div class="paymentTab">
                  <ListPlan :clientId="clientId" />
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="Course">
                <div class="courseTab">
                  <div class="list-header">
                    <a-button class="add-course" type="primary" @click="showCourseList"><PlusOutlined/>Add Course</a-button>
                  </div>
                  <Listofcourse :clientId="clientId" :watchKey="watchKey"/>
                  <!--<a-tabs v-model:activeKey="activeTabSub">
                    <a-tab-pane key="11" tab="Course List" :force-render="true">
                      <Listofcourse :clientId="clientId" :watchKey="watchKey"/>
                    </a-tab-pane>
                    <a-tab-pane key="12" tab="Combo Courses" :force-render="true">
                      <Combocourse :clientId="clientId" />
                    </a-tab-pane>
                    <a-tab-pane key="13" tab="Add Course">
                      <div class="list-header">
                        <a-button class="add-course" type="primary" @click="showCourseList"><PlusOutlined/>Add Course</a-button>
                      </div>
                    </a-tab-pane>
                  </a-tabs>-->
                </div>
              </a-tab-pane>
              <a-tab-pane key="3" tab="Candidates" force-render>
                <div class="candidatesTab">
                  <a-button type="primary" class="addcandidate" @click="addCandidateModel">
                    <UserAddOutlined /> Add New Candidate
                  </a-button>
                  <a-list item-layout="horizontal" :data-source="candidates">
                    <template #renderItem="{ item, index }">
                      <a-list-item>
                        <a-list-item-meta>
                          <template #title> {{ item.first_name }} {{ item.last_name }} </template>
                        </a-list-item-meta>
                        <template #actions>
                          <img src="../../../assets/images/deleteicon.svg" @click="showDeleteConfirm(item, index)" />
                          <div class="login-as" @click="showLoginConfirm(item)">
                            <LoginOutlined />
                          </div>
                        </template>
                      </a-list-item>
                    </template>
                  </a-list>
                </div>
              </a-tab-pane>
              <!--<a-tab-pane key="4" tab="Finish">
                <div class="finishTab">
                  <div class="icon">
                    <HeartOutlined :style="{ fontSize: '25px', color: '#ff7875' }" />
                  </div>
                  <div class="title">Thank You!</div>
                  <div class="message">You are just once click away</div>
                  <div class="action">
                    <a-button type="primary"><router-link to="/clients">Submit</router-link></a-button>
                  </div>
                </div>
              </a-tab-pane>-->
            </a-tabs>
            <div class="steps-action">
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 1" @click="prev">
                <ArrowLeftOutlined />
              </a-button>
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 3" style="margin-left: 8px" @click="next">
                <ArrowRightOutlined />
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-drawer v-if="courseListvisible" title="Available Course" placement="right" :closable="true" :visible="courseListvisible" :get-container="false"
        :wrap-style="{ position: 'absolute' }" @close="closeCourseList" width="500">
        <div class="course-list">
          <a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange"> Check all</a-checkbox>
          <a-checkbox-group v-model:value="checkedList" :options="availableCourse" />
        </div>
        <div class="course-list-footer">
          <div class="action">
            <a-button type="primary" @click="closeCourseList">Cancel</a-button>
            <a-button type="primary" @click="assignCourse" :loading="loading">Assign</a-button>
          </div>
        </div>
      </a-drawer>
      <a-modal title="Add New Candidate" :visible="visible" :wrapClassName="'addnew-candidate'" :destroyOnClose="true" :maskClosable="false" :footer="null" @cancel="handleCancel" width="1000px">
        <AddCandidate :clientId="clientId" @refreshCandidateList="refreshCandidate" />
      </a-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, createVNode, reactive, watch, toRefs } from 'vue'
import { LoginOutlined, HomeOutlined, ArrowLeftOutlined, ArrowRightOutlined, UserAddOutlined, ExclamationCircleOutlined, HeartOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import { notification, Modal } from 'ant-design-vue'
import { GoogleMap, Marker } from 'vue3-google-map'

import AddCandidate from '@/components/client/candidate/AddCandidate.vue'
import ListPlan from '@/components/agency/payment/ListPlans.vue'
import Listofcourse from '@/components/client/courses/Listofcourse.vue'

import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import IClient from '../../../interface/profile'
import ICourse from '../../../interface/course'

interface Coordinates {
  lat: number;
  lng: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    UserAddOutlined,
    LoginOutlined,
    GoogleMap,
    Marker,
    AddCandidate,
    ListPlan,
    Listofcourse,
    PlusOutlined
  },
  setup () {
    const center = ref<Coordinates>()
    const route = useRoute()
    const clientId = ref<string>(route.params.id as string)
    const client = ref<IClient>()
    const candidates = ref<Array<IClient>>([])
    const activeKey = ref('1')
    const watchKey = ref<number>(0)
    const activeTabSub = ref('11')
    const visible = ref<boolean>(false)
    const courseListvisible = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const availableCourse = ref([])
    const courses = ref<Array<ICourse>>([])
    const state = reactive({
      indeterminate: false,
      checkAll: false,
      checkedList: []
    })
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    const showCourseList = async () => {
      courseListvisible.value = true
    }
    const closeCourseList = () => {
      courseListvisible.value = false
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        checkedList: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const getClientDetails = async () => {
      const responce = await profileService.getProfileDetails(clientId.value)
      client.value = responce.data
      if (responce.data.address) {
        const coordinates = responce.data.address.location.coordinates
        center.value = { lat: coordinates[0], lng: coordinates[1] }
      }
    }
    const getClientCandidates = async () => {
      try {
        const responce = await profileService.getCandidates(clientId.value)
        candidates.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const addCandidateModel = async () => {
      visible.value = true
    }
    const handleCancel = async () => {
      visible.value = false
    }
    const refreshCandidate = async () => {
      getClientCandidates()
      handleCancel()
    }
    const deleteCandidate = async (data, index) => {
      try {
        // await profileService.deleteCandidate(data._id, data._id)
        const clientId = commonServices.getCurrentProfile()._id
        const subscriptionId = commonServices.getCurrentSubscription()._id
        await profileService.deleteCandidate({ profileId: data._id, clientId: clientId, subscriptionId: subscriptionId })
        candidates.value.splice(index, 1)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnAssignedCourse = async () => {
      try {
        availableCourse.value = []
        const responce = await courseService.getUnAssignedCourse(clientId.value)
        courses.value = responce.data
        for (const course of courses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      if (state.checkedList.length === 0) {
        closeCourseList()
      }
      const enroll = []
      try {
        loading.value = true
        for (const courseId of state.checkedList) {
          enroll.push({ enrolled_date: new Date(), profile: clientId.value, course: courseId })
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        closeCourseList()
        watchKey.value += 1
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showLoginConfirm = async (profile) => {
      console.log('profile', profile)
      Modal.confirm({
        title: 'Are you sure to login as candidate?',
        icon: createVNode(LoginOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to login as candidate?. This will login the user in new window.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
          window.open('/candidate/course?loginAs=candidate', '_blank')
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    watch(() => state.checkedList, val => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.checkAll = val.length === availableCourse.value.length
      console.log('state', state)
    })
    const showDeleteConfirm = async (data, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {}, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          deleteCandidate(data, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(() => {
      getClientDetails()
      getClientCandidates()
      getUnAssignedCourse()
    })
    return {
      activeKey,
      prev,
      next,
      showDeleteConfirm,
      center,
      client,
      candidates,
      visible,
      addCandidateModel,
      handleCancel,
      clientId,
      refreshCandidate,
      showCourseList,
      closeCourseList,
      onCheckAllChange,
      loading,
      availableCourse,
      assignCourse,
      state,
      ...toRefs(state),
      courseListvisible,
      activeTabSub,
      watchKey,
      showLoginConfirm
    }
  }
})
</script>

<style lang="scss">
.client-view {
  .header {
    background: #fff;
    padding: 15px;
    margin: 20px 10px 10px;
    border-radius: 10px;
    .add {
      float: right;
      .ant-btn {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        font-family: "TT Norms Pro Medium";
        a {
          color: #ffffff;
        }
      }
    }
  }
  .view-candidate {
    padding: 10px;
    .candidateDetails {
      background: #ffffff;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      width: 100%;
      .mapsection {
        height: 200px;
      }
      .contents {
        padding: 15px;
        text-align: left;
        h3 {
          color: #38B6FF;
          font-family: "TT Norms Pro Medium";
          font-size: 14px;
        }
        h4 {
          color: #646464;
          font-family: "TT Norms Pro Medium";
          font-size: 14px;
        }
        .fields {
          border-bottom: 1px solid #d9d9d9;
          margin: 0 0 10px 0;
          padding: 0 0 10px 0;
          label {
            display: block;
            font-family: "TT Norms Pro Bold";
          }
          span {
            font-family: "TT Norms Pro Light";
            margin: 0 0 10px 0;
            display: block;
          }
        }
        .contactperson {
          p {
            font-family: "TT Norms Pro Light";
            span {
              font-family: "TT Norms Pro Medium";
              display: block;
            }
          }
        }
      }
    }
    .tabsection {
      background: #ffffff;
      box-shadow: 0px 5px 10px #00000003;
      border-radius: 8px;
      padding: 15px;
      width: 100%;
      .ant-tabs-bar {
        border-bottom: 0;
        .ant-tabs-ink-bar-animated {
          display: none !important;
        }
        .ant-tabs-nav .ant-tabs-tab {
          border: 2px solid #38B6FF;
          color: #38B6FF;
          border-radius: 8px;
          padding: 5px 16px;
          width: -webkit-fill-available;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
          background-color: #38B6FF;
          color: #fff !important;
        }
        .ant-tabs-nav .ant-tabs-tab:active,
        .ant-tabs-nav .ant-tabs-tab:hover {
          color: #38B6FF;
        }
        .ant-tabs-nav-wrap {
          margin-bottom: 0px;
        }
        .ant-tabs-nav {
          width: 100%;
          & > div {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .paymentTab {
        background-color: #f9f9f9;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 0 10px 10px;
        .paymentrow {
          max-width: 750px;
          margin: auto !important;
        }
        .subscription,
        .credits {
          .body {
            padding: 0;
            .ant-table-body {
              td,
              th {
                padding: 10px;
              }
            }
            .ant-input-number {
              width: 70px;
            }
          }
          .header {
            padding: 20px 0 5px;
            margin: 15px 0 0 0;
            span {
              margin-left: 10px;
            }
          }
        }
      }
      .courseTab {
        background-color: #f9f9f9;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 10px;
        .list-header {
          height: 40px;
          .add-course {
            background-color: #38B6FF;
            border-color: #38B6FF;
            border-radius: 25px;
            float: right;
          }
        }
      }
      .candidatesTab {
        background-color: #f9f9f9;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 20px 30px;
        .addcandidate {
          background-color: #38B6FF;
          border-radius: 50px;
          border: none;
          box-shadow: none;
          margin: 0 0px 20px 0;
          float: right;
        }
        .ant-list {
          clear: both;
        }
        .login-as {
          width: 28px;
          height: 28px;
          background: #4caf50;
          color: #ffffff;
          line-height: 30px;
          border-radius: 50%;
        }
        .ant-list-item {
          border-bottom: none;
          background: #eef3f6;
          border-radius: 8px;
          margin: 0 0 15px 0;
          padding: 5px 0;
          .ant-avatar {
            font-family: "TT Norms Pro Medium";
            color: #646464;
            background: #d2f8ff;
          }
          .ant-list-item-meta {
            width: 100%;
          }
          .ant-list-item-meta-content {
            height: 36px;
            padding: 0 100px 0 15px;
            width: calc(100% - 260px);
            h4 {
              line-height: 36px;
              text-align: left;
              margin: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-transform: uppercase;
              font-size: 12px;
            }
          }
          .ant-list-item-action {
            position: absolute;
            right: 0;
            li {
              img {
                width: 28px;
              }
              .reloadicon {
                background: #ffc788;
                width: 28px;
                height: 28px;
                display: block;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                line-height: 28px;
              }
            }
          }
        }
      }
      .finishTab {
        background-color: #f9f9f9;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 50px 0;
        .title {
          font-size: 20px;
          padding: 10px 0 10px;
          font-family: "TT Norms Pro Bold";
          color: #646464;
        }
        .message {
          padding: 0 0 10px;
          padding: 0 0 15px;
          font-family: "TT Norms Pro Light";
        }
        .action {
          button {
            background: #38B6FF;
            border: 1px solid #38B6FF;
            border-radius: 20px;
            width: 100px;
          }
        }
      }
    }
    .steps-action {
      background-color: #f9f9f9;
      padding: 20px 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .ant-btn-danger {
        background-color: #ff7875;
        border-color: #ff7875;
      }
    }
  }
  .course-list {
    .ant-checkbox-group {
      width: 100%;
    }
    .ant-checkbox-wrapper {
      width: 100%;
      text-align: left;
      padding: 5px 0;
    }
  }
  .course-list-footer {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    .action {
      button {
        background-color: #38B6FF;
        border-color: #38B6FF;
        border-radius: 25px;
        margin-right: 5px;
      }
    }
  }
  .addnew-candidate {
    .ant-modal-body {
      padding: 0;
    }
    .add-candidate {
      padding: 0;
      .steps-action {
        text-align: center;
      }
      .body {
        padding: 0;
      }
    }
  }
}
</style>
